import { Controller } from "stimulus";
import autocomplete from "autocomplete.js";

// TODO: this file contains literal strings without internationalization! I18n

export default class extends Controller {
    static targets = ["field"];

    truncateString (string, maxLength = 20) {
        if (!string) return null;
        if (string.length <= maxLength) return string;
        return `${string.substring(0, maxLength)}&hellip;`;
    };
    escapeHtml(unsafe) {
        return unsafe
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    }

    search(query, callback) {
        const truncate = this.truncateString;

        if(query.length < 3) {
            callback([]);
            return;
        }
        const searchHintDummyResult = {
            title: `${query.replace(/(<([^>]+)>)/gi, "")} suchen`, // regexp so no HTML can be injected
            path: "/search/?q=" + encodeURIComponent(query),
            klaas: "Suggestion",
            image_url: "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OTAiIGhlaWdodD0iNDkwIj4KPHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMDAwIiBzdHJva2Utd2lkdGg9IjM2IiBzdHJva2UtbGluZWNhcD0icm91bmQiCmQ9Im0yODAsMjc4YTE1MywxNTMgMCAxLDAtMiwybDE3MCwxNzBtLTkxLTExNyAxMTAsMTEwLTI2LDI2LTExMC0xMTAiLz4KPC9zdmc+IA=="
        }

        const results = [searchHintDummyResult].concat(
            (window.itemsForSearch || []).filter(p => p.title.toLocaleLowerCase().includes(query.toLocaleLowerCase()) || (p.article_no != null && p.article_no != '' && p.article_no.toLocaleLowerCase() === query.toLocaleLowerCase()))
        );

        const maxResults = 8;
        if(results.length > maxResults) {
            const truncated_results = results.slice(0, maxResults).concat([
                {
                    title: "... und weitere",
                    path: "/search/?q=" + encodeURIComponent(query),
                    klaas: "Suggestion"
                }
            ]);
            callback(
                truncated_results
            )
        } else {
            callback(
                results
            )
        }
    }

    // TODO: track usage of autocomplete

    connect() {
        const truncate = this.truncateString;
        const escape = this.escapeHtml;
        this.ac = autocomplete(this.fieldTarget, { hint: false }, [
            {
                source: this.search,
                debounce: 200,
                templates: {
                    // TODO: already messy... this is why they installed Mustache:
                    //  https://www.mskog.com/posts/autocomplete-in-ruby-on-rails-using-stimulus/
                    suggestion: function (suggestion) {
                        return `<div title="${escape(suggestion.title)}">` +
                            // highlight/format special result items:
                            (suggestion.klaas === "Category" ? "<strong>" : "") +
                            (suggestion.klaas === "Suggestion" ? "<i>" : "") +
                            // add supplied image, or an empty SVG as fallback:
                            `<img class="autocomplete-image" src="${suggestion.image_url || 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEiIGhlaWdodD0iMSIvPg=='}"> 
            <span class="pe-1 small">${truncate(escape(suggestion.title))}</span>` +
                            (suggestion.klaas === "Category" ? "</strong>" : "") +
                            (suggestion.klaas === "Suggestion" ? "</i>" : "") +
                        "</div>";
                    },
                },
            },
        ]).on("autocomplete:selected", (event, suggestion, dataset, context) => {
            if (suggestion.path != null) {
                this.ac.autocomplete.setVal("");
                Turbo.visit(suggestion.path); // TODO: check if turbolinks available
            } else {
                this.ac.autocomplete.setVal(suggestion.title);

            }
        });
    }
}
